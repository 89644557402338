@media print {
  .col-sm-6 {
    width: 100%;
    float: none;
  }
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}

Handle on hover ::-webkit-scrollbar-thumb:hover {
  display: none;
}

.rowSistema {
  display: flex;
  flex-direction: row;
}

.colunaMenu {
  flex: 20%;
}

.colunaSistema {
  flex: 80%;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.containerFaixaRelatorio {
  background-color: #d24244;
  color: #ffffff;
  padding: 10px;
  font-size: 25px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
}

.itemImagemFaixaRelatorio {
  flex-direction: column;
  margin-top: 1%;
}

.ImagemFaixaRelatorio {
  height: 62px;
  width: 60px;
  top: 5%;
}

.itemFaixaRelatorio {
  flex-direction: column;
  margin-top: 1%;
  text-transform: uppercase;
  margin-left: 2%;
}

.descricaoItemRelatorio {
  line-height: 10px;
}

.avoidPageBreak {
  page-break-inside: avoid;
}

.sideBarBorderLight {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
.sideBarBorderDark {
  border-right: 1px solid #424549;
}
.menuSide {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.iconeMenuSide {
  font-size: 16px;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.buttonCustom{
  width: 100%;
  height: 39;
  margin-top: 4px;
}

.MuiCollapse-wrapper {
  transition: transform 300ms cubic-bezier(0.4, 0.0, 0.2, 1); /* Adjust the duration and timing function as needed */
}
